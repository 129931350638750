.collumnItem {
  width: 270px !important;
  height: 52px;
  margin-bottom: 34px;
  margin-left: 20px !important;
}

.headerBlock {
  display: flex;
  justify-content: space-between;
}

.headerStyle {
  height: auto !important;
  background: 0 !important;
  text-align: left;
}

.modalWindowForKeywords {
  width: 70% !important;
  height: auto !important;
}

.ant-layout {
  background: 0 !important;
}

.ant-layout-footer {
  background: 0 !important;
}

.ant-layout-content {
  background: 0 !important;
}

.ant-list-pagination {
  text-align: center !important;
}

.keywordsBreadcrumbsBlock {
  width: 50%;
}

.doCaseSensitiveOrNotBlock {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.changeCaseInSynonymsButton {
  margin-top: 0;
}

.makeCaseSensitiveTitle {
  font-weight: bold;
  font-size: 14px;
  color: #595959;
  margin-right: 10px;
  margin-bottom: 0;
}

.keywordsSearchImportExportBlock {
  width: 25%;
  margin: 20px 0;
}

.importExportButtonsBlock {
  display: flex;
  justify-content: space-between;
}

.exportKeywordButtonStyle {
  min-width: 120px !important;
  height: 40px !important;
  color: #2f80ed !important;
  background-color: #ffffff !important;
  border: 1px solid #2f80ed !important;
}

.inputForChooseFile {
  display: none;
}

.importKeywordsButton {
  color: #2f80ed !important;
  height: 40px;
  text-align: center;
  min-width: 120px;
  line-height: 35px;
  background-color: #ffffff !important;
  border: 1px solid #2f80ed !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
