.companyInfoBlockItemCard {
  background: #f3f6f9 !important;
  border-radius: 5px !important;
  width: 295px;
  height: 160px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.infoBlockActionItem {
  color: #2f80ed;
  cursor: pointer;
}

.imageIconBlockWithCircle {
  position: relative;
  min-width: 17%;
}

.infoBlockIcon {
  color: #2f80ed !important;
  z-index: 2;
  opacity: 1;
  position: absolute;
  left: 8px;
  top: 3px;
}

.companyInfoBlockCircle {
  top: -5px;
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.2;
  z-index: 1;
  background-color: #2f80ed;
  border-radius: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.cardCompanyInfoBlockTitle {
  color: #6c8196;
  font-size: 14px;
}

.contentForCarItem {
  text-align: left;
  padding: 0px 32px;
  color: #424a56 !important;
  font-size: 18px !important;
}

.cardTitleForCompanyIfoBlock {
  display: flex;
  justify-content: start;
}

.companyProfileBlockItemList {
  height: 100px;
  overflow: hidden;
}

.companyProfileBlockItemList:hover {
  overflow-y: scroll;
}

.companyProfileBlockItemString {
  margin: 0 !important;
  color: #424a56 !important;
  font-size: 16px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyProfileBlockItemString:hover {
  overflow-y: scroll;
}
