.companyProfileTypologyPanel {
  text-align: left;
}

.industryTitlePanel {
  min-width: 20%;
  padding-top: 10px;
  line-height: 0 !important;
}

.segmentTitlePanel {
  min-width: 20%;
  padding-top: 10px;
  line-height: 0 !important;
}

.listItemImagesStyle {
  color: #2f80ed !important;
  padding-top: 5px;
  padding-right: 5px;
}

.segmentIconStyle {
  color: #d0d0d0 !important;
  padding-top: 5px;
  padding-right: 5px;
}

.categoryIconStyle {
  color: #9ec7ff !important;
  padding-top: 5px;
  padding-right: 5px;
}

.headerForIndustryPanel {
  display: flex;
}

.itemCountWithPictureBlock {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.countAndLabelTextBlock {
  display: flex;
}

.addNewIndustryButtonOnCompanyProfile {
  width: 100%;
  margin-top: 20px;
  height: 70px !important;
  border: 1px dashed #2f80ed !important;
  color: #2f80ed !important;
  background-color: rgba(47, 128, 237, 0.05) !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}

.layoutForSpinnerCompanyProfileTopologyBlock {
  height: 10vh;
}

.spinComponentForCompanyProfileTopologyBlock {
  margin: 20px !important;
}
