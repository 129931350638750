.headerStyleCompanies {
  text-align: center;
}

.filterBlockWithSelect {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.companiesListTableBlock {
  width: 1000%;
  overflow: auto;
}
.companiesListSidebar {
  padding-right: 24px;
  .ant-menu {
    border: none;
  }
}
.filtersSelectComponentBlock {
  width: 70%;
}

.companiesFilterColumn15 {
  width: 20% !important;
  margin: 10px 0;
}

.addCompanyButton {
  margin: 20px 0;
  width: 200px;
  border-radius: 50px !important;
}

.companiesListConfirmButton {
  color: #1890ff !important;
  font-size: 20px;
}

.companiesListUnConfirmButton {
  color: #868383 !important;
  font-size: 20px;
}

.companiesArrayBlockOnTable {
  display: grid;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companiesMatch {
  min-width: 400px;
  background: #2f80ed !important;
  color: #ffffff !important;
  border-radius: 50px !important;
}

.companiesWebsiteColumn {
  width: 15%;
  text-overflow: ellipsis;
}
.companiesSegmentColumn {
  width: 10%;
}
.companyFiltersTopologyPath {
  width: auto;
  min-width: 200px;
  font-size: 12px;
  word-wrap: break-word;
}
