.listItemText {
  margin: 0;
}

.listItemContentBlock {
  display: flex;
  width: 80%;
  padding-left: 40px;
}

.listItemRow {
  justify-content: space-between;
  border-bottom: 0;
}

.listItemBlockGlobalKeyword {
  display: flex;
  width: 40%;
}

.listItemBlockTopologyBlock {
  width: auto;
  display: flex;
}

.cardListItem {
  width: 100%;
  margin: 15px 0 !important;
  box-shadow: 3px 0px 10px 10px rgba(74, 83, 96, 0.05);
  border: 0 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.listItemImagesStyleRemoveButtonIcon {
  color: #ffffff !important;
  padding-top: 5px;
  padding-right: 5px;
}

.globalKeywordSearchViewButton {
  margin-right: 15px;
  min-width: 80px !important;
  height: 32px !important;
  color: #2f80ed !important;
  background-color: #ffffff !important;
  border: 1px solid #2f80ed !important;
}

.globalKeywordSearchListButton {
  color: #1890ff !important;
  font-size: 20px;
  margin: 0 20px;
}
