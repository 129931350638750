.companyProfileKeywordListItemTag {
  min-width: 200px;
  text-align: left;
  min-height: 40px;
  margin: 8px !important;
  background: #edf5ff !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}

.companyProfileKeywordListItemText {
  color: #2f80ed;
  font-size: 16px;
  line-height: 2;
}

.keywordItemSemanticData {
  color: #2f80ed;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  line-height: 2;
}

.companyProfileTopologyKeywordItemIcon {
  font-size: 16px;
  margin-top: 10px;
  margin-left: 10px;
  color: #1890ff !important;
}
.companyProfileTopologyKeywordTagItemContainer {
  display: flex;
  justify-content: space-between;
}
