.navbar {
  display: flex;
  align-items: center;
  max-width: 70%;
  width: 55%;
}

.navigationContainer {
  padding: 0 35px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 3;
  transition: 0.2s;
  &.scrolled {
    box-shadow: 0 4px 20px rgba(146, 146, 146, 0.1);
  }
}

.logoutButton {
  color: #808080 !important;
  background: #f2f2f2 !important;
  border: none !important;
  width: 140px;
}

.pageTitle {
  font-size: 64px;
  text-align: center;
}

.removeBackGroundOnLayout {
  background: 0 !important;
}
.ant-table-column-title {
  white-space: nowrap;
}
.selectButton {
  width: 302px;
  height: 55px;
  border-radius: 50px;
}

.columnTitle {
  font-size: 24px;
  text-align: center;
}

.addDataSourceInput {
  width: 100% !important;
}

.iconAddONButton {
  color: #2f80ed;
  padding-right: 10px;
}

.addLanguageStyleButton {
  width: 100% !important;
  margin-top: 20px !important;
}

.navbar__item {
  color: #808080;
  cursor: pointer;
  font-size: 16px;
  margin-right: 40px;
}

.backButtonItem {
  color: #808080 !important;
  background: #f2f2f2 !important;
  border: none !important;
  width: 140px;
}

.activeNavBarItem {
  color: #2f80ed;
  font-weight: bold;
}

.setting_drop_down {
  background-color: white;
  display: grid;
}

.nav_link_settings {
  color: #808080;
  cursor: pointer;
  margin: 10px 0;
}

.logoHeaderBlock {
  width: 170px;
  margin-right: 20px;
}
.header-userinfo {
  display: inline-block;
  padding: 0 16px;
}
