.report-new {
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
  .report-new-form {
    margin-top: 24px;
    margin-bottom: 84px;
  }
  .report-new-field {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
  }
  .reports-new-next {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: #2f80ed;
    border-radius: 5px;
    color: white;
    line-height: 50px;
    width: 400px;
    font-size: 24px;
    cursor: pointer;
  }
  .reports-new-disabled {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 5px;
    color: white;
    line-height: 50px;
    width: 400px;
    font-size: 24px;
    cursor: not-allowed;
  }
}

.newReportChooseCompanySelect {
  width: 100%;
}
