.addNewCompanyLabelForTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #676767;
}

.companyProfileHeader {
  height: auto !important;
}

.labelForTitle {
  text-align: center;
  display: grid;
}

.companyInfoBlockItemsList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
}

.companyProfileAddCompanyForm {
  margin: 0 30px !important;
}

.companyProfileItemBlockColumn {
  width: auto !important;
  margin: 15px;
}

.addCompanyCalendarIcon {
  margin: 0 5px;
}

.addCompanyProfileLoadingDate {
  margin: 0 5px;
  color: #454545;
}

.addCompanyProfileDate {
  margin: 0 5px;
  color: #797979;
}

.addCompanyButton {
  margin-right: 20px;
  min-width: 400px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #2f80ed !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.runScraperAddCompanyPage {
  min-width: 400px;
  background: #2f80ed !important;
  color: #ffffff !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.companyProfileItemBlockListItem {
  border-bottom: 0 !important;
  padding: 0 !important;
}

.arrayInputComponent {
  width: 90% !important;
}

.removeStringIconButton {
  margin-left: 5px;
}

.submitButtonOnForm {
  width: 90% !important;
}

.submitFormButtonInputForm {
  width: 100% !important;
}

.companyProfileAddIsClientField {
  display: grid !important;
  justify-content: center !important;
}

.ant-list-empty-text {
  display: none;
}
.persons-form__item.persons-form__item {
  padding-bottom: 32px;
  border-bottom: 1px solid #ccc;
  .removeStringIconButton {
    position: absolute;
    top: 0;
  }
}
.contacts-modal__wrap {
  .ant-table-tbody > tr:hover > td {
    background: transparent !important;
  }
  .ant-table-content {
    margin-bottom: 32px;
  }
}
