.cardItem {
    padding: 0;
    width: 264px;
    height: 52px;
    border-radius: 5px !important;
    box-shadow: 3px 0px 10px 10px rgba(74, 83, 96, 0.05);
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.activeItem {
    padding: 0;
    width: 264px;
    height: 52px;
    border-radius: 5px !important;
    border: 1px solid #2F80ED !important;
    box-shadow: 3px 0px 10px 10px rgba(74, 83, 96, 0.05);
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.inputTextStyle {
    width: 90%;
    font-size: 18px !important;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 15px;
    text-overflow: ellipsis;
}

.cardHeader {
    display: flex;
    justify-content: flex-end;
    height: 15px;
    font-size: 12px;
    color: #2F80ED;
    padding-right: 13px;
    padding-top: 4px;
}

.ant-card-body {
    padding: 0 !important;
}

.cardHeaderEditButton {
    padding-right: 10px;
    cursor: pointer;
}

.formEditInput {
    width: 220px !important;
    border: 0 !important;
    margin-right: 45px !important;
    font-size: 18px !important;
}

