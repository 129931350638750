.titleForAddSynonyms {
    font-size: 24px;
    text-align: center
}

.inputBlockForNewSynonym {
    display: flex;
    justify-content: space-between;
}

.newSynonymINput {
    width: 60% !important;
}

.listOfSynonyms {
    width: 100%;
}

.selectCountryForNewsynonym {
    width: 35% !important;
}

.addNewSynonymModalFooter {
    text-align: right;
}

.addNewSynonymRadioBlock {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}

.addNewSynonymButtonBlock {
    text-align: right;
}

.addNewSynonymButton {
    width: 200px !important;
    background: #F2F2F2 !important;
    border-radius: 6px !important;
    color: #828282 !important;
    border-color: #F2F2F2 !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
}

.doneButtonForCloseModalWindow {
    width: 200px !important;
    background: #2F80ED !important;
    border-radius: 6px !important;
    color: #FFFFFF !important;
    border-color: #2F80ED !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}

.doneButtonContainer {
    margin-top: 30px;
    text-align: center;
}