.loginForm {
  text-align: center;
  margin: auto !important;
  width: 460px;
  height: 600px;

  @media screen and (max-width: 490px) {
    padding: 0 10px;
    width: 100%;
  }
}

.titleWrapper {
  padding-top: 70px;
}

.inputWrapper {
  margin-bottom: 38px;
}

.nextBtn {
  width: 400px;

  @media screen and (max-width: 490px) {
    width: 300px;
  }
}

.inputGroup {
  margin-bottom: 50px;
}

.ant-input::placeholder {
  text-align: center;
}

.loginPageWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  overflow: auto;
}