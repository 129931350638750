.titleWrapper {
    width: 100%;
    padding-top: 70px;
}

.dataSourceRow {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
}

.columnDataSourceList {
    margin: 0 50px;
    width: 30%;
}

.columnAddNewDataSource {
    margin: 0 50px;
    width: 30%;
}

.choseedCountryList {
    text-align: left;
}

.listItemStyle {
    display: flex !important;
    justify-content: space-between;
}

.addLanguageStyleButton {
    color: #2F80ED !important;
    background-color: #ffffff !important;
    border: 1px solid #2F80ED !important;
}

.inputForChooseFile {
    display: none;
}

.importDataSourceFileButton {
    margin-top: 20px;
    cursor: pointer;
    color: #2F80ED !important;
    line-height: 2.5;
    background-color: #ffffff !important;
    border: 1px solid #2F80ED !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}