.companyProfileDownloadReportButton {
    min-width: 400px;
    border: 1px solid #2F80ED !important;
    box-sizing: border-box;
    color: #2F80ED !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}

.companyProfileDownloadReportButtons {
    display: flex;
    justify-content: center;
    max-width: 900px;
    margin: auto;
}

.companyProfileMatchReportButton {
    min-width: 400px;
    background: #2F80ED !important;
    color: #FFFFFF !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}

.companyProfileDownloadProfileButton {
    float: right;
    margin-right: 50px;
    border: 1px solid #2F80ED !important;
    box-sizing: border-box;
    color: #2F80ED !important;
    box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 30px !important;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    -ms-border-radius: 30px !important;
    -o-border-radius: 30px !important;
}

.companyProfileDateIcon {
    margin: 0 5px;
    margin-left: 200px;
}

.companyProfileTopKeywordsBlock {
    margin-top: 60px;
}

.companyProfileTopologyBlock {
    margin-top: 60px;
}