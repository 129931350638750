.titleWrapper {
    width: 100%;
    padding-top: 70px;
}

.columnForListDataSource {
    margin: 0 50px;
    width: 30%;
}

.columnWithAddForm {
    margin: 0 50px;
    width: 30%;
}

.choseedCountryList {
    text-align: left;
}

.listItemStyle {
    display: flex !important;
    justify-content: space-between;
}

.addLanguageStyleButton {
    color: #2F80ED !important;
    background-color: #ffffff !important;
    border: 1px solid #2F80ED !important;
}

.addLanguageSelect {
    width: 100%;
}