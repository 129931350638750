.layoutForSpinner {
  height: 100vh;
}

.spinComponentStyle {
  margin: calc(50vh - 20px) !important;
}

.closeIconColor {
  color: #2f80ed !important;
  padding-top: 2px;
}

.headerTextAlign {
  text-align: center;
}

.breadCrumbsComponent {
  margin: 40px 0 !important;
  margin-bottom: 0px !important;
}

.selectFiledStyle {
  width: 302px;
  height: 55px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.ant-breadcrumb {
  text-align: left;
}

.ant-layout-header {
  background: none !important;
  padding: 0 !important;
}

.ant-table-footer {
  text-align: left;
}
