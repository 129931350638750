.addCompanyProfileButton {
  min-width: 100px;
  margin: 0 5px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.companiesListSearchButton {
  margin: 0 5px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.companiesExportAllButton {
  margin: 0 5px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.companiesImportExportButtons {
  min-width: 120px;
  margin: 0 5px;
  line-height: 2;
  font-size: 14px;
  height: 32px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #2f80ed !important;
  background-color: #ffffff !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.companiesImportIconStyle {
  padding-right: 5px;
}
