.report {
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
  .report-form {
    margin-top: 24px;
    margin-bottom: 84px;
  }
  .report-field {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
  }
  .report-value {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: normal;
  }
  .report-import {
    border: 1px solid #2f80ed;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #2f80ed;
    margin: 24px 0;
    cursor: pointer;
  }
  .report-import-file {
    display: none;
    width: 0;
    visibility: hidden;
  }
  .reports-disabled {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 5px;
    color: white;
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    width: 400px;
    line-height: 40px;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: not-allowed;
  }
  .report-url-add {
    bottom: 12px;
    border: 1px dashed #2f80ed;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #f2f6fb;
    width: 100%;
    line-height: 14px;
    min-width: 320px;
    margin: 6px auto;
    padding: 6px 0;
    cursor: pointer;
    color: #2f80ed;
    img {
      height: 14px;
      width: 14px;
      margin-right: 12px;
    }
  }
}

.report-export {
  background-color: #2f80ed !important;
  border-radius: 5px !important;
  color: white !important;
  width: 400px;
  font-size: 24px;
  line-height: 40px;
  display: inline-block;
  font-weight: 400;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  margin: 20px auto;
}

.reports-next {
  bottom: 24px;
  margin: 40px 0;
  background: #2f80ed;
  border-radius: 5px;
  color: white;
  width: 400px;
  font-size: 24px;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  font-weight: 400;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
}

.report-export-companies {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 45px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #2f80ed;
  border: 1px solid #2f80ed;
  background: white;
  border-radius: 2px;
  width: 320px;
  margin: 0 0 0 calc(100% - 320px);
}

.report-name-status {
  font-size: 28px !important;
  line-height: 50px;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  text-align: center;
}
.textEditor {
  margin-bottom: 50px;
}

.editorTabButtonsBlock {
  margin-top: 30px;
}
