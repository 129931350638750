.industryPageWrapper {
    width: 100%;
}

.collumnItem {
    width: 270px !important;
    height: 52px;
    margin-bottom: 34px;
}

.addNewIndustryButton {
    width: 100%;
    height: 70px !important;
    border: 1px dashed #2F80ED !important;
    color: #2F80ED !important;
    background-color: rgba(47, 128, 237, 0.05) !important;
    box-sizing: border-box;
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
}