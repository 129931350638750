.subMenuItem {
  text-align: left;
  background-color: #fafafa;
  margin-right: 10px;
}

.companyFiltersMinMaxFields {
  display: flex;
}

.companyFiltersButtonBlock {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.companyFiltersButtonFilterButton {
  width: 30%;
}

.companyFiltersButtonSubmitFilterResulButton {
  width: 30%;
}

.companiesFiltersRadioButtonsGroup {
  display: grid !important;
}

.companiesFiltersRadioButton {
  margin: 5px 0 !important;
}

.companiesDropDownMenu {
  width: auto !important;
  left: 280px !important;
  top: 360px !important;
}

.ant-select-dropdown{
  width: auto !important;
}