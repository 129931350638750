.globalKeywordSearchButtonsBlock {
  width: 90%;
  margin: 10px 0;
}

.globalKeywordSearchSearchButton {
  margin: 0 5px;
  border: 1px solid #2f80ed !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.searchInputsBlock {
  width: 40%;
  display: flex;
}

.globalKeywordSearchSearchInput {
  width: 75% !important;
}

.globalKeywordsListBlock {
  margin-top: 60px;
}
