.reports {
  width: 100%;
  margin-top: 50px;
  padding-bottom: 60px;
  .reports-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: #f2f6fb;
    border-radius: 6px;
    margin-top: 12px;
    padding: 12px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 12px;
    }
    .reports-item-col {
      text-align: left;
      width: 40%;
    }
    .reports-item-btn {
      text-align: right;
      width: 20%;
    }
  }
  .reports-add {
    bottom: 12px;
    border: 1px dashed #2f80ed !important;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: rgba(47, 128, 237, 0.05) !important;
    width: 100%;
    height: 70px !important;
    padding: 12px 0;
    font-size: 18px;
    color: #2f80ed !important;
    margin-top: 40px;
  }
}
.reportsEditButton {
  color: #4ab376 !important;
  font-size: 20px;
  margin-right: 20px;
}
.reportsListPagination {
  margin: 20px 0 !important;
}
