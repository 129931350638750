.topKeywordsItemListItemTitle {
    font-size: 24px;
    line-height: 28px;
}

.topKeywordsListItem {
    border-bottom: 0 !important;
    text-align: left;
}

.firstListItemTopKeywords {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    color: #2F80ED;
    background-color: #c1d3e8;
    width: 90%;
    font-size: 18px;
    line-height: 21px;
}

.secondListItemTopKeywords {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    color: #2F80ED;
    background-color: #d9e6f7;
    width: 60%;
    font-size: 18px;
    line-height: 21px;
}

.thirdListItemTopKeywords {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    color: #2F80ED;
    background-color: #eef3f9;
    width: 40%;
    font-size: 18px;
    line-height: 21px;
}