.companyProfileWordItem {
  min-width: 250px;
  text-align: left;
  min-height: 40px;
  margin: 8px !important;
  background: #edf5ff !important;
  display: flex !important;
  justify-content: space-between;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}

.companyProfileWordItemText {
  color: #2f80ed;
  font-size: 16px;
  display: inline-block !important;
  text-overflow: ellipsis;
  line-height: 2;
}

.wordItemButtonsBlock {
  float: right;
  width: 50px;
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}

.addWordItemToCompanyKeywordSelect {
  width: 100%;
  margin-top: 10px !important;
}

.companyProfileWordItemButtons {
  color: #1890ff !important;
  font-size: 18px;
}

.companyProfileWordItemCount {
  margin-left: 20px;
  color: #2f80ed;
  font-size: 16px;
}

.companyProfileWeordItemTextContainer {
  overflow: hidden;
  max-width: 80%;
}
